import topic from '../topic';
import { getNoCatch } from '@api/request';

export const apayAPI = {
  getApays: topic.apayTopic + '/get_apays',
  getSgins: topic.apayTopic + '/get_sgins',
  getApayById: topic.apayTopic + '/get_apay_by_id',
  addApay: topic.apayTopic + '/add_apay',
  editApay: topic.apayTopic + '/edit_apay',
  deleteApayByIds: topic.apayTopic + '/delete_apay_by_ids',
  exportApayPdfById: topic.apayTopic + '/pdf/export_apay_pdf_by_id',
  getApayActuNo: params => getNoCatch(`${topic.apayTopic}/get_apay_actu_no`, params),
  getInsts: params => getNoCatch(`${topic.instTopic}/get_insts_v1`, params),
  getSconNoBySconList: params => getNoCatch(`${topic.apayTopic}/getSconNoBySconList`, params)
};
