<template>
  <div class="vg_wrapper">
    <add-header @submit="submit('apayForm')" @cancel="cancel" :apayeFlag="true"></add-header>
    <el-form ref="apayForm" :model="apayForm" :rules="rules" label-width="120px" size="mini">
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="申请类型" prop="apay_application_type">
            <el-select v-model="apayForm.apay_application_type" size="small" placeholder="请选择" filterable @change="changeApplicationType">
              <el-option
                v-for="item in [
                  { value: 1, label: '模具费' },
                  { value: 2, label: '打样费' },
                  { value: 3, label: '原材料' },
                  { value: 4, label: '预付款' },
                  { value: 5, label: '借款' },
                  { value: 6, label: '内部转账' },
                  { value: 7, label: '其他' }
                ]"
                :key="item.value + item.label"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="源单号" prop="scon_no">
            <el-input v-model="apayForm.scon_no" maxlength="20" placeholder="请填写">
              <template #append>
                <el-link class="vg_cursor_hander" type="primary" @click="sconNoDialogVisible = true">导入</el-link>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="付款类型" prop="apay_payment_type">
            <el-radio v-model="apayForm.apay_payment_type" :label="1">普通</el-radio>
            <el-radio v-model="apayForm.apay_payment_type" :label="2">紧急</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="是否需要凭证" prop="apay_voucher">
            <el-radio v-model="apayForm.apay_voucher" :label="1">是</el-radio>
            <el-radio v-model="apayForm.apay_voucher" :label="2">否</el-radio>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="是否有发票" prop="apay_invoice">
            <el-radio v-model="apayForm.apay_invoice" :label="1">是</el-radio>
            <el-radio v-model="apayForm.apay_invoice" :label="2">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="申请事由" prop="reason_for_application">
            <el-input v-model="apayForm.reason_for_application" show-word-limit placeholder="请填写申请事由"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="归属部门" prop="reim_dept_id">
            <el-select v-model="apayForm.reim_dept_id" size="small" placeholder="请选择部门" filterable>
              <el-option v-for="item in deptList" :key="item.dept_id" :value="item.dept_id" :label="item.dept_name"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="币种" prop="cust_currency">
            <el-select filterable v-model="apayForm.apay_currency" placeholder="请选择币种">
              <el-option v-for="item in custCurrencyList" :key="item.id" :label="item.param1" :value="item.param1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="付款单位" prop="cptt_id">
            <el-select v-model="apayForm.cptt_id" size="small" placeholder="请选择" filterable>
              <el-option v-for="item in cpttList" :key="item.value" :value="item.value" :label="item.label"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="金额" prop="apay_total">
            <el-input
              v-model="apayForm.apay_total"
              @input="val => (apayForm.apay_total = keep2Decimal(val))"
              maxlength="13"
              show-word-limit
              placeholder="请填写金额"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="8">
          <el-form-item label="收款单位" prop="apay_payee">
            <el-select v-model="apayForm.apay_payee" allow-create clearable default-first-option filterable placeholder="请选择文章标签">
              <el-option v-for="item in payeeList" :key="item.label + item.value" :label="item.label" :value="item.label"></el-option>
            </el-select>
            <!--            <el-input v-model="apayForm.apay_payee" autosize show-word-limit placeholder="请填写收款单位">
              <template #append>
                <el-link type="primary" class="vg_cursor_hander" @click="unitDialogVisible = true">导入</el-link>
              </template>
            </el-input>-->
          </el-form-item>
        </el-col>
        <el-col :md="8">
          <el-form-item label="发票号码" prop="apay_actu_no" v-if="apayForm.apay_invoice === 1">
            <el-input @blur="checkNum(apayForm.apay_actu_no)" v-model="apayForm.apay_actu_no" maxlength="30" show-word-limit placeholder="请填写发票号码">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="银行名称" prop="apay_bank">
            <el-input v-model="apayForm.apay_bank" maxlength="100" show-word-limit placeholder="请填写银行名称"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="银行账号" prop="apay_account">
            <el-input v-model="apayForm.apay_account" type="textarea" maxlength="50" show-word-limit placeholder="请填写银行账号"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="还款方式及时间" prop="apay_repay">
            <el-input v-model="apayForm.apay_repay" type="textarea" placeholder="请填写还款方式及时间"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mb_5">
        <!--        <el-col :md="24">-->
        <!--          <el-form-item label="申请付款理由" prop="apay_reason">-->
        <!--            <el-input v-model="apayForm.apay_reason" type="textarea" :autosize="{ minRows: 3 }" placeholder="请填写申请付款理由"> </el-input>-->
        <!--          </el-form-item>-->
        <!--        </el-col>-->
      </el-row>
      <el-row class="vg_mb_5">
        <el-col :md="24">
          <el-form-item label="备注" prop="apay_remark">
            <el-input v-model="apayForm.apay_remark" type="textarea" :autosize="{ minRows: 3 }" placeholder="请填写备注"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog width="70%" :visible.sync="unitDialogVisible" @opened="getUnitData">
      <el-button type="info" size="small" class="vg_mb_8" icon="el-icon-refresh-right" @click="refresh()"> 刷新</el-button>
      <search-table
        ref="multiTable"
        :data="tableData"
        :tableRowKey="'apay_unit_id'"
        :columns="tableProperties"
        v-loading="loadFlag"
        :totalPage="totalPage"
        @row-dblclick="dbClickJp"
        @getTableData="getUnitData"
      >
      </search-table>
    </el-dialog>
    <el-dialog :visible.sync="sconNoDialogVisible" width="70%" @opened="getSconNoData">
      <el-button class="vg_mb_8" icon="el-icon-refresh-right" size="small" type="info" @click="refresh2()"> 刷新</el-button>
      <DynamicUTable
        ref="multiTable2"
        v-loading="loadFlag2"
        :columns="tableProperties2"
        :need-check-box="false"
        :need-search="true"
        :table-data="sconNoTableData"
        :totalPage="totalPage2"
        @getTableData="getSconNoData"
        @row-dblclick="dbClickJp2"
      >
      </DynamicUTable>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { apayAPI } from '@api/modules/apay';
import { cpttAPI } from '@api/modules/comptitle';
import { deptAPI } from '@api/modules/department';
import addHeader from '@/views/component/addHeader';
import inputUser from '@/views/component/inputUser';
import SearchTable from '@/components/table/SearchTableV2.vue';
import { sconNoTableProperties, unitTableProperties } from '@/views/SettleManagement/ApayManage/apay';
import { keep2Decimal } from '@assets/js/regExUtil';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { getCptt, getCustomerInfo, getSupp } from '@api/public';
import { optnAPI } from '@api/modules/optn';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'ApayAddMain',
  components: {
    DynamicUTable,
    SearchTable,
    addHeader,
    inputUser
  },
  data() {
    return {
      sconNoDialogVisible: false,
      unitDialogVisible: false,
      totalPage: 0,
      loadFlag: true,
      totalPage2: 0,
      loadFlag2: true,
      tableProperties: unitTableProperties,
      tableProperties2: sconNoTableProperties,
      tableData: [],
      sconNoTableData: [],
      custCurrencyList: [],
      rules: {
        reason_for_application: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        apay_application_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        apay_payment_type: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        apay_total: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        apay_payee: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        apay_account: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        apay_bank: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        apay_repay: [{ required: false, trigger: ['blur', 'change'], message: ' ' }],
        apay_invoice: [{ required: true, trigger: ['blur', 'change'], message: ' ' }],
        cptt_id: [{ required: true, trigger: ['blur', 'change'], message: ' ' }]
      },
      apayForm: {
        apay_voucher: 2,
        apay_payment_type: 1,
        apay_application_type: null,
        reason_for_application: null,
        apay_type: null,
        apay_source: null,
        apay_actu_no: null,
        cptt_id: null,
        apay_total: null,
        reim_dept_id: this.$cookies.get('userInfo').dept_id,
        apay_payee: null,
        apay_bank: null,
        apay_account: null,
        apay_reason: null,
        apay_enclo: null,
        apay_repay: null,
        apay_remark: null,
        scon_no: null
      },
      cpttList: [],
      deptList: [],
      suppList: [],
      payeeList: []
    };
  },
  created() {
    this.initData();
  },
  methods: {
    keep2Decimal,
    async initData() {
      this.getDept();
      this.suppList = await getSupp('default', [], 'supp_name');
      this.cpttList = await getCptt();
      this.getCustCurrency();
    },
    //提交表单
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$refs[formName].validate(valid => {
            if (valid) {
              this.saveInfo();
            } else {
              console.log('error submit!');
              this.$message.warning('请把必填内容补充完整');
              return false;
            }
          });
        })
        .catch(() => {});
    },
    async changeApplicationType(val) {
      if (val === 6) {
        this.payeeList = this.cpttList;
      } else {
        this.payeeList = this.suppList;
      }
    },
    // 获取币种
    getCustCurrency() {
      get(optnAPI.getOptnByPermId, { perm_id: 10015 })
        .then(res => {
          if (res.data.code === 0) {
            this.custCurrencyList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 保存
    saveInfo() {
      const staffForm = this.$refs.userData.getData();
      let apayForm = JSON.parse(JSON.stringify(this.apayForm));
      apayForm = Object.assign(apayForm, staffForm);
      post(apayAPI.addApay, apayForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: '保存成功'
            });
            const permId = this.$route.query.perm_id;
            this.jump('/apay_edit', {
              key: UrlEncode.encode(
                JSON.stringify({
                  perm_id: permId,
                  form_id: res.data.data.form_id
                })
              )
            });

            this.resetForm('apayForm');
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.initData();
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('apayForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    // 获取部门
    getDept() {
      get(deptAPI.getAllDeptsV1)
        .then(res => {
          if (res.data.code === 0) {
            this.deptList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    //
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    dbClickJp(row) {
      this.apayForm.apay_payee = row.inst_cname;
      this.apayForm.apay_bank = row.inst_bank;
      this.apayForm.apay_account = row.inst_code;
      this.unitDialogVisible = false;
    },
    dbClickJp2(row) {
      this.apayForm.scon_no = row.scon_no;
      this.apayForm.scon_id = row.scon_id;
      this.sconNoDialogVisible = false;
    },
    async getUnitData() {
      await (this.loadFlag = true);
      await apayAPI.getInsts(this.$refs.multiTable.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag = false;
      });
    },
    async getSconNoData() {
      await (this.loadFlag2 = true);
      await apayAPI.getSconNoBySconList(this.$refs.multiTable2.searchForm).then(({ data }) => {
        this.sconNoTableData = data.list;
        this.totalPage2 = data.total;
        this.loadFlag2 = false;
      });
    },
    refresh() {
      this.$refs.multiTable.resetFields();
      this.getUnitData();
    },
    refresh2() {
      this.$refs.multiTable2.resetFields();
      this.getSconNoData();
    },
    checkNum(val) {
      apayAPI.getApayActuNo({ apay_actu_no: val }).then(({ data }) => {});
    }
  }
};
</script>

<style scoped lang="scss">
.vd_bortop {
  border-top: #dcdfe6 solid 1px;
  padding-top: 20px;
}
.vd_dash {
  width: 100%;
  height: 1px;
  border-top: 1px dashed #c2c2c2;
  margin: 10px 0;
}
</style>
